'use strict';

const inputErrorClass = 'has-error';

class newAccountController {
	// @ngInject
	constructor(
		$scope,
		$state,
		$stateParams,
		$window,
		$filter,
		$timeout,
		newAccountService,
		accountUtilService,
		validationFormFields,
		propostaSetupAccountService,
		sfConfig,
		feedbackMessages,
		breadcrumbHelper,
		cepHelper,
		alteracaoDadosSetupAccountService,
		crivoHelper,
		autoCompleteService
	) {
		this.$state = $state;
		this.sfConfig = sfConfig;
		this.$stateParams = $stateParams;
		this.$window = $window;
		this.$filter = $filter;
		this.messages = feedbackMessages;
		this.newAccountService = newAccountService;
		this.accountUtilService = accountUtilService;
		this.validationFormFields = validationFormFields;
		this.valid = validationFormFields;
		this.propostaSetupAccountService = propostaSetupAccountService;
		this.alteracaoDadosService = alteracaoDadosSetupAccountService;
		this.$scope = $scope;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.$scope.$emit('changeMainShowLoading', false);
		this.cepHelper = cepHelper;
		this.cepHelper.setPristine();
		this.crivoHelper = crivoHelper;
		this.crivoHelper.setPristine();
		this.autoComplete = autoCompleteService;
		this.$timeout = $timeout;

		this.newAccountObj = {
			id: null,
			personal: {
				cpf: {
					value: '',
					classError: ''
				},
				name: {
					value: '',
					classError: ''
				},
				socialName: {
					value: '',
					classError: ''
				},
				gender: {
					value: '',
					classError: ''
				},
				birthDate: {
					value: '',
					classError: ''
				},
				birthCountry: {
					value: '',
					classError: ''
				},
				nationality: {
					value: '',
					classError: ''
				},
				hasSecondNationality: {
					value: 'N',
					classError: ''
				},
				secondNationality: {
					value: '',
					classError: ''
				},
				rg: {
					value: '',
					classError: ''
				},
				emitter: {
					value: '',
					classError: ''
				},
				uf: {
					value: '',
					classError: ''
				},
				expeditionDate: {
					value: '',
					classError: ''
				},
				cel: {
					value: '',
					classError: ''
				},
				phone: {
					value: '',
					classError: ''
				},
				email: {
					value: '',
					classError: ''
				},
				nac: {
					value: 'B',
					classError: ''
				},
				RE: {
					value: '',
					classError: ''
				}
			},

			address: {
				cep: {
					value: '',
					classError: '',
					hasError: false
				},
				address: {
					value: '',
					classError: ''
				},
				addressNumber: {
					value: '',
					classError: ''
				},
				complement: {
					value: '',
					classError: ''
				},
				neighborhood: {
					value: '',
					classError: ''
				},
				city: {
					value: '',
					classError: ''
				},
				uf: {
					value: '',
					classError: ''
				},
				isResidenciaFiscalBrasil: {
					value: 'true',
					classError: ''
				},
				residenciaFiscal: {
					id: '',
					value: '',
					label: '',
					classError: ''
				}
			},

			wishReceiveEmail: false
		};

		this.emailMsgError = '';
		this.phoneMsgError = '';
		this.celMsgError = '';
		this.birthDateMsgError = '';
		this.expeditionDateMsgError = '';
		this.addressMsgError = '';
		this.cityMsgError = '';
		this.neighborhoodMsgError = '';
		this.errorMessages = [];
		this.setStateToGo();

		this.datepickerOptions = {
			minDate: new Date(1700, 1, 3)
		};

		this.showSave = false;

		if (this.hasValidUrlParams()) {
			/*
            if (this.isMyAccount()) {

                this.$state.go('my-account-person-edit');
                return;
            } */

			this.showSave = true;
			this.loadAccountInformation();
		}

		this.configureBreadCrumb();
		// setInterval(() => { console.log('form', this.newAccountObj); }, 20000);
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Cadastro', '/caponline/secured#!/conta/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Novo cadastro Pessoa Física', '#', false, true);
	}

	isMyAccount() {
		return this.$stateParams.id === this.sfConfig.idUSerAccount;
	}

	hasValidUrlParams() {
		return this.$stateParams && this.$stateParams.id ? true : false;
	}

	loadAccountInformation() {
		this.$scope.$emit('changeMainShowLoading', true);

		this.newAccountService.getAccountDetails(this.$stateParams.id).then((response) => {
			console.log('Resposta getAccount Details: ', response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.newAccountObj.id = this.$stateParams.id;
				let birthDate = '';
				if (response.accountPFInfo.personal.birthDate !== '') {
					const dateB = response.accountPFInfo.personal.birthDate.split('/')[0];
					const monthB = response.accountPFInfo.personal.birthDate.split('/')[1];
					const yearB = response.accountPFInfo.personal.birthDate.split('/')[2];
					birthDate = `${monthB}/${dateB}/${yearB}`;
				}
				let expeditionDate = '';
				if (response.accountPFInfo.personal.expeditionDate !== '') {
					const expD = response.accountPFInfo.personal.expeditionDate.split('/')[0];
					const expM = response.accountPFInfo.personal.expeditionDate.split('/')[1];
					const expY = response.accountPFInfo.personal.expeditionDate.split('/')[2];
					expeditionDate = `${expM}/${expD}/${expY}`;
				}
				this.newAccountObj.personal = {
					cpf: {
						value: response.accountPFInfo.personal.cpf,
						classError: ''
					},
					name: {
						value: response.accountPFInfo.personal.name,
						classError: ''
					},
					gender: {
						value: response.accountPFInfo.personal.gender === 'Masculino' ? 'M' : 'F',
						classError: ''
					},
					birthDate: {
						value: response.accountPFInfo.personal.birthDate !== '' ? Date.parse(birthDate) : null,
						classError: ''
					},
					rg: {
						value: response.accountPFInfo.personal.rg,
						classError: ''
					},
					emitter: {
						value: response.accountPFInfo.personal.emitter,
						classError: ''
					},
					uf: {
						value: response.accountPFInfo.personal.uf,
						classError: ''
					},
					expeditionDate: {
						value: response.accountPFInfo.personal.expeditionDate !== '' ? Date.parse(expeditionDate) : null,
						classError: ''
					},
					cel: {
						value: response.accountPFInfo.personal.cel,
						classError: ''
					},
					phone: {
						value: response.accountPFInfo.personal.phone,
						classError: ''
					},
					email: {
						value: response.accountPFInfo.personal.email,
						classError: ''
					},
					nac: {
						value: response.accountPFInfo.personal.nac,
						classError: ''
					},
					RE: {
						value: response.accountPFInfo.personal.RE,
						classError: ''
					},
					birthCountry: {
						value: response.accountPFInfo.personal.birthCountry,
						label: response.accountPFInfo.personal.birthCountry,
						classError: ''
					},
					nationality: {
						value: response.accountPFInfo.personal.nationality,
						label: response.accountPFInfo.personal.nationality,
						nacionalidade: response.accountPFInfo.personal.nationality,
						classError: ''
					},
					hasSecondNationality: {
						value: response.accountPFInfo.personal.secondNationality ? 'S' : 'N',
						classError: ''
					},
					secondNationality: {
						value: response.accountPFInfo.personal.secondNationality,
						label: response.accountPFInfo.personal.secondNationality,
						classError: ''
					},
					socialName: {
						value: response.accountPFInfo.personal.socialName,
						classError: ''
					}
				};
				this.newAccountObj.address = {
					cep: {
						value: response.accountPFInfo.address.cep,
						classError: ''
					},
					address: {
						value: response.accountPFInfo.address.address,
						classError: ''
					},
					addressNumber: {
						value: response.accountPFInfo.address.addressNumber,
						classError: ''
					},
					complement: {
						value: response.accountPFInfo.address.complement,
						classError: ''
					},
					neighborhood: {
						value: response.accountPFInfo.address.neighborhood,
						classError: ''
					},
					city: {
						value: response.accountPFInfo.address.city,
						classError: ''
					},
					uf: {
						value: response.accountPFInfo.address.uf,
						classError: ''
					},
					isResidenciaFiscalBrasil: {
						value: response.accountPFInfo.address.isResidenciaFiscalBrasil ? 'true' : 'false',
						classError: ''
					},
					residenciaFiscal: {
						id: response.accountPFInfo.address.idResidenciaFiscal,
						label: response.accountPFInfo.address.residenciaFiscal,
						classError: ''
					}
				};
				this.newAccountObj.wishReceiveEmail = response.accountPFInfo.personal.acceptEmail;
			}

			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	closeDatepickerOnTabPress(keyEvent) {
		if (keyEvent.which === 9) {
			$('.uib-datepicker-popup').remove();
		}
	}

	focusBirthDate() {
		$('#birthDateId').focus();
	}

	focusExpDate() {
		$('#expeditionDateId').focus();
	}

	setStateToGo() {
		if (this.propostaSetupAccountService.getFromProposta()) {
			this.accountUtilService.setStateToGo('proposta.new');
		} else if (this.propostaSetupAccountService.getFromCartaCaucaoLocator() || this.propostaSetupAccountService.getFromCartaCaucaoEstateAdmin()) {
			this.accountUtilService.setStateToGo('proposta.caucao');
		} else if (this.propostaSetupAccountService.getFromPropostaPayment()) {
			this.accountUtilService.setStateToGo('proposta.payment');
		} else if (this.alteracaoDadosService.getFromAlteracaoImovel()) {
			this.accountUtilService.setStateToGo('case.alteracao-dados.alteracaoImovel');
		} else if (this.alteracaoDadosService.getFromMudancaLocador()) {
			this.accountUtilService.setStateToGo('case.alteracao-dados.mudancaLocador');
		} else if (this.alteracaoDadosService.getFromInclusaoCaucaoLocador() || this.alteracaoDadosService.getFromInclusaoCaucaoAdministradorImovel()) {
			this.accountUtilService.setStateToGo('case.alteracao-dados.inclusaoCaucao');
		} else {
			this.accountUtilService.setStateToGo('account-detail');
		}
	}

	invokeNewAccount(newAccount) {
		if (newAccount.personal.hasSecondNationality.value == 'N') {
			newAccount.personal.secondNationality.value = null;
		}

		this.newAccountService.createNewAccount(newAccount).then((response) => {
			// console.log(response);

			if (response && response.errorMessages && response.errorMessages.length > 0) {
				this.errorMessages = response.errorMessages;
				this.$scope.$emit('changeMainShowLoading', false);
				this.$window.scrollTo(0, 0);
			} else if (response.isSuccessful) {
				this.errorMessages = [];

				if (response.account) {
					if (this.propostaSetupAccountService.getFromProposta()) {
						this.propostaSetupAccountService.setNewAccountInfo(response.account);
						// this.propostaSetupAccountService.setFromProposta(false);
					} else if (this.propostaSetupAccountService.getFromCartaCaucaoLocator()) {
						this.propostaSetupAccountService.setNewLocatorInfo(response.account);
						// this.propostaSetupAccountService.setFromCartaCaucaoLocator(false);
					} else if (this.propostaSetupAccountService.getFromCartaCaucaoEstateAdmin()) {
						this.propostaSetupAccountService.setNewEstateAdminInfo(response.account);
						// this.propostaSetupAccountService.setFromCartaCaucaoEstateAdmin(false);
					} else if (this.propostaSetupAccountService.getFromPropostaPayment()) {
						console.log('Entrou no set new subscriber ', response.account);
						this.propostaSetupAccountService.setNewSubscriberInfo(response.account);
					} else if (this.alteracaoDadosService.getFromAlteracaoImovel()) {
						this.alteracaoDadosService.setNewLocador(response.account);
					} else if (this.alteracaoDadosService.getFromMudancaLocador()) {
						this.alteracaoDadosService.setNewLocador(response.account);
					} else if (this.alteracaoDadosService.getFromInclusaoCaucaoLocador()) {
						this.alteracaoDadosService.setNewLocador(response.account);
					} else if (this.alteracaoDadosService.getFromInclusaoCaucaoAdministradorImovel()) {
						this.alteracaoDadosService.setAdministradorImovel(response.account);
					}

					if (this.accountUtilService.getStateToGo() === 'account-detail') {
						this.$scope.$emit('changeMainShowLoading', false);
						this.$state.go('account-detail', { id: response.account.Id });
					} else {
						this.$scope.$emit('changeMainShowLoading', false);
						this.$state.go(this.accountUtilService.getStateToGo());
					}
				}
			}
			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	newAccountRequest(form) {
		form.$setSubmitted();
		// console.log('form.$invalid', this.validateInputs(), form.$invalid, form);

		const newAccountObj = angular.copy(this.newAccountObj);
		const me = this;

		if (this.validateInputs()) {
			const cpfCnpj = newAccountObj.personal.cpf.value;
			const nome = newAccountObj.personal.name.value;
			const dataNascimento = this.$filter('date')(newAccountObj.personal.birthDate.value, 'dd-MM-yyyy');

			newAccountObj.personal.birthDate.value = this.$filter('date')(newAccountObj.personal.birthDate.value, 'dd/MM/yyyy');
			newAccountObj.personal.expeditionDate.value = this.$filter('date')(newAccountObj.personal.expeditionDate.value, 'dd/MM/yyyy');

			console.log('--> dataNascimento: ', dataNascimento);

			this.newAccountService.prevencaoValidaPessoa(cpfCnpj, nome, dataNascimento).then((response) => {
				console.log('--> response prevencaoValidaPessoa: ', response);

				if (!response.isSuccessful) {
					const message = response.errorMessages && response.errorMessages[0] ? response.errorMessages[0] : 'Serviço de prevenção de dados indisponível';
					me.messages.showErrorMessage(me.$scope, message);
					me.cepHelper.setPristine();

					this.$scope.$emit('changeMainShowLoading', false);
					//this.errorMessages = ['Revise os campos marcados em vermelho.'];
					this.$window.scrollTo(0, angular.element(document.querySelector('.has-error')).offsetTop);
				} else {
					this.errorMessages = [];

					if (!newAccountObj.address.residenciaFiscal) {
						newAccountObj.address.residenciaFiscal = {
							value: 'Brasil',
							label: 'Brasil'
						};
					}

					console.log('new', newAccountObj);
					this.invokeNewAccount(newAccountObj);
				}
			});
		} else {
			this.$scope.$emit('changeMainShowLoading', false);
			this.errorMessages = ['Revise os campos marcados em vermelho.'];
			this.$window.scrollTo(0, angular.element(document.querySelector('.has-error')).offsetTop);
		}
	}

	validateInputs() {
		this.$scope.$emit('changeMainShowLoading', true);
		let result = true;

		// this.newAccountObj.personal.birthDate.value = $('#birthDateId').val();
		// this.newAccountObj.personal.expeditionDate.value = $('#expeditionDateId').val();

		if (!this.accountUtilService.isValidCpf(this.newAccountObj.personal.cpf.value)) {
			this.newAccountObj.personal.cpf.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.personal.cpf.classError = '';
		}

		if (
			this.accountUtilService.hasInvalidSequence(this.newAccountObj.personal.name.value) ||
			this.accountUtilService.hasSequencedSpace(this.newAccountObj.personal.name.value) ||
			!this.accountUtilService.hasLastName(this.newAccountObj.personal.name.value) ||
			this.validationFormFields.hasBadExpression(this.newAccountObj.personal.name.value) ||
			this.newAccountObj.personal.name.value.length < 5
		) {
			this.newAccountObj.personal.name.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.personal.name.classError = '';
		}

		if (!this.newAccountObj.personal.gender.value) {
			this.newAccountObj.personal.gender.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.personal.gender.classError = '';
		}

		// if (!this.newAccountObj.personal.rg.value || !this.accountUtilService.isValidRg(this.newAccountObj.personal.rg.value)) {
		//  this.newAccountObj.personal.rg.classError = inputErrorClass;
		//  result = false;
		// } else {
		//  this.newAccountObj.personal.rg.classError = '';
		// }

		this.newAccountObj.personal.rg.classError = '';
		this.newAccountObj.personal.emitter.classError = '';
		this.newAccountObj.personal.uf.classError = '';
		this.newAccountObj.personal.expeditionDate.classError = '';

		if (this.newAccountObj.personal.rg.value && !this.accountUtilService.isValidRg(this.newAccountObj.personal.rg.value)) {
			this.newAccountObj.personal.rg.classError = inputErrorClass;
			this.rgMsgError = 'Campo inválido.';
			result = false;
		} else if (this.newAccountObj.personal.rg.value && this.accountUtilService.isValidRg(this.newAccountObj.personal.rg.value)) {
			console.log('identidade informada');
			this.newAccountObj.personal.rg.classError = '';

			if (!this.newAccountObj.personal.emitter.value) {
				this.newAccountObj.personal.emitter.classError = inputErrorClass;
				result = false;
			} else {
				this.newAccountObj.personal.emitter.classError = '';
			}

			if (!this.newAccountObj.personal.uf.value) {
				this.newAccountObj.personal.uf.classError = inputErrorClass;
				result = false;
			} else {
				this.newAccountObj.personal.uf.classError = '';
			}

			const parsedExpeditionDate = new Date(this.newAccountObj.personal.expeditionDate.value);

			if (!parsedExpeditionDate) {
				this.newAccountObj.personal.expeditionDate.classError = inputErrorClass;
				this.expeditionDateMsgError = 'Campo obrigatório.';
				result = false;
			} else if (!this.accountUtilService.isBeforeToday(parsedExpeditionDate)) {
				this.newAccountObj.personal.expeditionDate.classError = inputErrorClass;
				this.expeditionDateMsgError = 'Campo inválido.';
				result = false;
			} else {
				this.newAccountObj.personal.expeditionDate.classError = '';
			}

			if (parsedExpeditionDate < parsedBirthDate) {
				this.newAccountObj.personal.birthDate.classError = inputErrorClass;
				this.newAccountObj.personal.expeditionDate.classError = inputErrorClass;
				this.birthDateMsgError = 'Campo inválido.';
				this.expeditionDateMsgError = 'Campo inválido.';
				result = false;
			}
		}

		console.log(this.newAccountObj);
		const parsedBirthDate = new Date(this.newAccountObj.personal.birthDate.value);

		if (!parsedBirthDate) {
			this.newAccountObj.personal.birthDate.classError = inputErrorClass;
			this.birthDateMsgError = 'Campo obrigatório';
			result = false;
		} else {
			this.newAccountObj.personal.birthDate.classError = '';
		}

		if (this.newAccountObj.personal.cel.value && !this.accountUtilService.isValidPhoneNumber(this.newAccountObj.personal.cel.value)) {
			this.newAccountObj.personal.cel.classError = inputErrorClass;
			this.celMsgError = 'Campo inválido.';
			result = false;
		} else if (!this.newAccountObj.personal.cel.value) {
			this.newAccountObj.personal.cel.classError = inputErrorClass;
			this.celMsgError = 'Campo obrigatório.';
			result = false;
		} else {
			this.newAccountObj.personal.cel.classError = '';
		}

		if (this.newAccountObj.personal.phone.value && !this.accountUtilService.isValidPhoneNumber(this.newAccountObj.personal.phone.value)) {
			this.newAccountObj.personal.phone.classError = inputErrorClass;
			this.phoneMsgError = 'Campo inválido.';
			result = false;
		} else if (!this.newAccountObj.personal.phone.value) {
			this.newAccountObj.personal.phone.classError = inputErrorClass;
			this.phoneMsgError = 'Campo obrigatório.';
			result = false;
		} else {
			this.newAccountObj.personal.phone.classError = '';
		}

		if (!this.newAccountObj.personal.email.value) {
			this.newAccountObj.personal.email.classError = inputErrorClass;
			this.emailMsgError = 'Campo obrigatório.';
			result = false;
		} else if (!this.accountUtilService.isValidEmail(this.newAccountObj.personal.email.value)) {
			this.newAccountObj.personal.email.classError = inputErrorClass;
			this.emailMsgError = 'Formato de email inválido.';
			result = false;
		} else {
			this.newAccountObj.personal.email.classError = '';
		}

		if (!this.newAccountObj.address.cep.value) {
			this.newAccountObj.address.cep.classError = inputErrorClass;
			this.cepMsgError = 'Campo obrigatório.';
			result = false;
		} else if (!this.validationFormFields.isValidCep(this.newAccountObj.address.cep.value)) {
			this.newAccountObj.address.cep.classError = inputErrorClass;
			this.cepMsgError = 'Campo inválido.';
			result = false;
		} else if (this.newAccountObj.address.cep.hasError) {
			this.newAccountObj.address.cep.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.address.cep.classError = '';
		}

		if (!this.newAccountObj.address.address.value) {
			this.newAccountObj.address.address.classError = inputErrorClass;
			this.addressMsgError = 'Campo obrigatório.';
			result = false;
		} else {
			/*else if (this.newAccountObj.address.address.value.length < 5) {
            this.newAccountObj.address.address.classError = inputErrorClass;
            this.addressMsgError = 'O campo precisa ter pelo 5 letras';
            result = false;
        }*/
			this.newAccountObj.address.address.classError = '';
		}

		if (!this.newAccountObj.address.addressNumber.value) {
			this.newAccountObj.address.addressNumber.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.address.addressNumber.classError = '';
		}

		if (!this.newAccountObj.address.neighborhood.value) {
			this.newAccountObj.address.neighborhood.classError = inputErrorClass;
			this.neighborhoodMsgError = 'Campo obrigatório.';
			result = false;
		} else {
			this.newAccountObj.address.neighborhood.classError = '';
		}

		if (!this.newAccountObj.address.city.value) {
			this.newAccountObj.address.city.classError = inputErrorClass;
			this.cityMsgError = 'Campo obrigatório.';
			result = false;
		} else if (this.newAccountObj.address.city.value.length < 3) {
			this.newAccountObj.address.city.classError = inputErrorClass;
			this.cityMsgError = 'O campo precisa ter pelo menos 3 letras';
			result = false;
		} else {
			this.newAccountObj.address.city.classError = '';
		}

		if (!this.newAccountObj.address.uf.value) {
			this.newAccountObj.address.uf.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.address.uf.classError = '';
		}

		if (
			this.newAccountObj.address.isResidenciaFiscalBrasil.value == 'false' &&
			!this.hasPaisDeResidencia()
		) {
			console.log('Entrou no Pais de Residencia obrigatório');
			this.newAccountObj.address.residenciaFiscal = {
				classError: inputErrorClass
			};

			result = false;
		} else {
			console.log('Entrou no Pais de Residencia OK');
			this.newAccountObj.address.residenciaFiscal.classError = '';
		}

		if (!this.newAccountObj.personal.nationality || !this.newAccountObj.personal.nationality.value) {
			this.newAccountObj.personal.nationality = {
				value: '',
				classError: inputErrorClass
			};

			result = false;
		}

		if (!this.newAccountObj.personal.birthCountry || !this.newAccountObj.personal.birthCountry.value) {
			this.newAccountObj.personal.birthCountry = {
				value: '',
				classError: inputErrorClass
			};

			result = false;
		}

		if (this.newAccountObj.personal.hasSecondNationality.value == 'S') {
			if (!this.newAccountObj.personal.secondNationality || !this.newAccountObj.personal.secondNationality.value) {
				this.newAccountObj.personal.secondNationality = {
					value: '',
					classError: inputErrorClass
				};

				result = false;
			}
		} else {
			this.newAccountObj.personal.secondNationality.value = null;
		}

		// console.log(JSON.stringify(this.newAccountObj));

		return result;
	}

	hasPaisDeResidencia() {

		if (!!this.newAccountObj.address.residenciaFiscal) {
			return !!this.newAccountObj.address.residenciaFiscal.id
		} else {
			return !!this.newAccountObj.address.residenciaFiscal;
		}

	}

	consultarCrivo() {
		const me = this;
		const cpf = me.newAccountObj.personal.cpf.value;

		if (this.accountUtilService.isValidCpf(cpf)) {
			me.$scope.$emit('changeMainShowLoading', true);

			console.log('---- 1. VAI CHAMAR RECAPTCHA: ');

			//RECAPTCHA
			grecaptcha.ready(function () {
				grecaptcha
					.execute('6Ld7HnMaAAAAALsutf2GlIhn9Qb-reY-1ir-38uE', {
						action: 'submit'
					})
					.then(function (token) {
						console.log('---- 2. GTOKEN: ', token);

						me.crivoHelper.consultarCrivo(cpf, token, (response) => {
							console.log('--> response: ', response);

							if (!response.isSuccessful) {
								const message = response.errorMessages && response.errorMessages[0] ? response.errorMessages[0] : me.crivoHelper.remoteCallErrorMessage;
								me.messages.showErrorMessage(me.$scope, message);
								me.cepHelper.setPristine();
							} else {
								console.log('--> consultaCrivo resultado: ', response);
								console.log('--> data de nascimento: ', new Date(response.req_dataNascimento));
								console.log('--> sexo: ', response.req_sexo);
								me.newAccountObj.personal.name.value = response.req_nome.slice(0, 42);
								me.newAccountObj.personal.birthDate.value = new Date(response.req_dataNascimento);
								me.newAccountObj.personal.gender.value = response.req_sexo;

								me.messages.showErrorMessage(me.$scope, '');
							}

							me.$scope.$emit('changeMainShowLoading', false);
						});
					});
			});
		} else {
			me.crivoHelper.setPristine();
			me.newAccountObj.personal.name.value = null;
			me.newAccountObj.personal.birthDate.value = null;
			me.newAccountObj.personal.gender.value = null;
			me.messages.showErrorMessage(me.$scope, '');
		}
	}

	getAddressFromCEP() {
		const me = this;
		const cep = me.newAccountObj.address.cep.value;

		if (cep.length == 9 && cep.indexOf('-') == 5) {
			me.$scope.$emit('changeMainShowLoading', true);
			me.cepHelper.getAddressFromCEP(cep, (response) => {
				me.newAccountObj.address.address.value = response.logradouro;
				me.newAccountObj.address.neighborhood.value = response.bairro;
				me.newAccountObj.address.city.value = response.cidade;
				me.newAccountObj.address.uf.value = response.uf;

				if (!response.isSuccessful) {
					me.newAccountObj.address.cep.classError = inputErrorClass;
					if (me.cepHelper.remoteCallErrorLimitWasExceded()) {
						me.newAccountObj.address.cep.classError = '';
						me.newAccountObj.address.cep.hasError = false;
						me.messages.showErrorMessage(me.$scope, me.cepHelper.remoteCallErrorMessage);
					} else {
						me.newAccountObj.address.cep.hasError = true;
						me.cepMsgError = response.errorMessages[0];
					}
					me.cepHelper.setPristine();
				} else {
					me.newAccountObj.address.cep.classError = '';
					me.newAccountObj.address.cep.hasError = false;
					me.cepMsgError = '';
					me.messages.showErrorMessage(me.$scope, '');
				}

				this.$scope.$emit('changeMainShowLoading', false);
			});
		} else {
			me.cepHelper.setPristine();
			me.newAccountObj.address.address.value = null;
			me.newAccountObj.address.neighborhood.value = null;
			me.newAccountObj.address.city.value = null;
			me.newAccountObj.address.uf.value = null;
		}
	}

	searchPaisFocusOut() {
		console.log('searchPaisFocusOut', ' | this : ', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.newAccountObj.personal.birthCountry);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchPais');
			}
		}, 100);
	}

	searchPaisChanged() {
		console.log('searchPaisChanged', ' | this : ', this);

		const newAccount = this.$parent.newAccount;
		newAccount.autoComplete.resetSelected(newAccount.newAccountObj.personal, 'birthCountry');
	}

	searchPais(searchString) {
		console.log('searchPais | ', searchString, ' | this : ', this);
		return this.$parent.newAccount.newAccountService.searchPais(searchString);
	}

	searchPaisSelect(item) {
		console.log('searchPaisSelect', ' | this : ', this);

		if (!item) {
			return;
		}

		console.log('item:', item);

		const newAccount = this.$parent.newAccount;

		newAccount.newAccountObj.personal.birthCountry = item.originalObject;

		newAccount.newAccountObj.personal.nationality = '';

		//this.autoComplete.clearInput(this.$scope, 'searchNacionalidade');

		console.log('nacionalidade teste 1:', newAccount.newAccountObj.personal.nationality);

		/*
		newAccount.newAccountObj.personal.nationality = {
			value: item.originalObject.nacionalidade,
			label: item.originalObject.nacionalidade
		};
		*/

		newAccount.autoComplete.resetSelected(newAccount.newAccountObj.personal, 'nationality');
		document.getElementById('searchNacionalidade_value').value = '';

		//newAccount.newAccountObj.personal.nationality.nacionalidade = item.originalObject.nacionalidade;
		newAccount.newAccountObj.personal.nationality = {
			value: item.originalObject.nacionalidade,
			label: item.originalObject.nacionalidade
		};

		document.getElementById('searchNacionalidade_value').value = item.originalObject.nacionalidade;

		console.log('nacionalidade teste 2:', newAccount.newAccountObj.personal.nationality);
		console.log('nacionalidade teste 3:', document.getElementById('searchNacionalidade_value').value);
	}

	searchNacionalidade(searchString) {
		console.log('searchNacionalidade | ', searchString, ' | this : ', this);
		return this.$parent.newAccount.newAccountService.searchNacionalidade(searchString);
	}

	searchNacionalidadeFocusOut() {
		console.log('searchNacionalidadeFocusOut', ' | this : ', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.newAccountObj.personal.nationality);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchNacionalidade');
			}
		}, 100);
	}

	searchNacionalidadeChanged() {
		console.log('searchNacionalidadeChanged', ' | this : ', this);

		const newAccount = this.$parent.newAccount;
		newAccount.autoComplete.resetSelected(newAccount.newAccountObj.personal, 'nationality');
	}

	searchNacionalidadeSelect(item) {
		console.log('searchNacionalidadeSelect', ' | this : ', this);

		if (!item) {
			return;
		}

		const newAccount = this.$parent.newAccount;

		newAccount.newAccountObj.personal.nationality = item.originalObject;
	}

	searchSegundaNacionalidadeFocusOut() {
		console.log('searchSegundaNacionalidadeFocusOut', ' | this : ', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.newAccountObj.personal.secondNationality);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchSegundaNacionalidade');
			}
		}, 100);
	}

	searchSegundaNacionalidadeChanged() {
		console.log('searchNacionalidadeChanged', ' | this : ', this);

		const newAccount = this.$parent.newAccount;
		newAccount.autoComplete.resetSelected(newAccount.newAccountObj.personal, 'secondNationality');
	}

	searchSegundaNacionalidadeSelect(item) {
		console.log('searchSegundaNacionalidadeSelect', ' | itrm selecionado : ', item);

		if (!item) {
			return;
		}

		const newAccount = this.$parent.newAccount;

		newAccount.newAccountObj.personal.secondNationality = item.originalObject;
		newAccount.newAccountObj.personal.hasSecondNationality.value = 'S';
	}

	searchResidenciaFiscal(searchString) {
		console.log('searchResidenciaFiscal | ', searchString, ' | this : ', this);
		return this.$parent.newAccount.newAccountService.searchResidenciaFiscal(searchString);
	}

	searchResidenciaFiscalSelect(item) {
		console.log('searchResidenciaFiscalSelect', ' | this : ', this);

		if (!item) {
			return;
		}

		const newAccount = this.$parent.newAccount;

		console.log('searchResidenciaFiscalSelect: ', item.originalObject);

		newAccount.newAccountObj.address.residenciaFiscal = item.originalObject;
	}

	searchResidenciaFiscalFocusOut() {
		console.log('searchResidenciaFiscalFocusOut', ' | this : ', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.newAccountObj.address.residenciaFiscal);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchResidenciaFiscal');
			}
		}, 100);
	}

	searchResidenciaFiscalChanged() {
		console.log('searchResidenciaFiscalChanged', ' | this : ', this);

		const newAccount = this.$parent.newAccount;
		newAccount.autoComplete.resetSelected(newAccount.newAccountObj.address, 'residenciaFiscal');
	}

	searchNoResultText() {
		const resultText = 'Nenhum país encontrado';
		return resultText;
	}

	searchNoResultNacionalidadeText() {
		const resultText = 'Nenhuma nacionalidade encontrada';
		return resultText;
	}
}

module.exports = newAccountController;
