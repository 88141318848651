'use strict';

import VisualForceApi from '../../core/sfdc/visual-force-api.js';

class NewJuridicalAccountService extends VisualForceApi {
	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	createNewAccount(newAccount) {
		const cap_access_token = localStorage.getItem('cap_access_token');
		newAccount.accessToken = cap_access_token;

		return super.invoke('CapCommunityAccountControllerExt.createNewAccountPj', newAccount);
	}

	getAccountDetails(accountId) {
		const cap_access_token = localStorage.getItem('cap_access_token');

		return super.invoke('CapCommunityAccountControllerExt.getAccountDetails', accountId, cap_access_token);
	}

	searchResidenciaFiscal(searchKeyWord) {
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem('cap_access_token');

		super.invoke('CapCommunityAccountControllerExt.getResidenciaFiscal', searchKeyWord, cap_access_token).then((data) => {
			deferred.resolve(data.paises);
		});

		return deferred.promise;
	}

	searchPais(searchKeyWord) {
		console.log('entrou searchPais service');
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem('cap_access_token');

		super.invoke('CapCommunityPaisCtrlExt.consultarPais', searchKeyWord, cap_access_token).then((data) => {
			deferred.resolve(data.paises);
		});

		return deferred.promise;
	}

	prevencaoValidaEmpresa(cpfCnpj, nome) {
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem('cap_access_token');

		const data = {
			cpfCnpj,
			nome
		};

		console.log('data enviada prevencaoValidaEmpresa: ', data);

		super.invoke('CapCommunityCrivoCtrlExt.prevencaoValidaPessoaJuridica', data).then((data) => {
			deferred.resolve(data);
		});

		return deferred.promise;
	}
}

module.exports = NewJuridicalAccountService;
