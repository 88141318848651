'use strict';

class propostaCaucaoController {

	/* @ngInject */
	constructor($scope, $uibModal, $window, $timeout, propostaCaucaoApi, formControlService, validationFormFields, autoCompleteService, propostaSetupAccountService, sfConfig, feedbackMessages, cepHelper, accountUtilService, crivoHelper) {
		//console.log('propostaCaucaoController');

		this.propostaCaucaoApi = propostaCaucaoApi;
		this.formControl = formControlService;
		this.formField = this.formControl.field;
		this.valid = validationFormFields;
		this.autoComplete = autoCompleteService;
		this.messages = feedbackMessages;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$window = $window;
		this.propostaSetupAccountService = propostaSetupAccountService;
		this.$uibModal = $uibModal;
		this.sfConfig = sfConfig;
		this.cepHelper = cepHelper;
		this.cepHelper.setPristine();
		this.crivoHelper = crivoHelper;
		this.crivoHelper.setPristine();
		this.accountUtilService = accountUtilService;
		this.tipoProposta = sessionStorage.getItem("tipo_lista");

		this.required = {};
		this.validated = {};

		this.formField.formStep = 'proposta.caucao';

		// this.checkIfHasUserInfo();
		this.pristineWarnings();
		this.checkOnLoadRequiredFields();
		this.setFromCartaCaucao(false);
		this.listenValidationRequest();
		this.listenDuplicatedCpfValidationResponse();
		this.listenRequiredRequest();
		if (this.sfConfig.imobiliaria.Id !== "") {
			this.formField.estateAdminIsNotLocator = false; //JIRA:CAP-2670 
			if (this.formField.estateAdmin === null) {
				//this.formField.estateAdmin = { Id: this.sfConfig.imobiliaria.Id, Name: this.sfConfig.imobiliaria.Name, isPersonAccount: false }; //JIRA:CAP-2670 
			}
		}
	}

	pristineWarnings() {
		this.warning = {
			clientCepError: {
				show: false,
				value: null
			}
		};
	}

	resetWarning(field) {
		this.warning[`${field}Error`].show = false;
		this.warning[`${field}Error`].value = null;
	}

	hasWarningClientCep() {
		return this.warning.clientCepError.show;
	}

	validateDuplicatedCpf() {
		//console.log('emitindo duplicatedCpfValidation');
		this.$scope.$emit('duplicatedCpfValidation', this.formField);
	}

	listenDuplicatedCpfValidationResponse() {
		this.$scope.$on('duplicatedCpfValidationResponse', (event, response) => {
			//console.log('escutando duplicatedCpfValidationResponse')
			if (response.length === 0) {
				this.validated.locatorError = false;
				this.validated.estateAdminError = false;
			} else {
				for (let i = 0; i < response.length; i++) {
					if ((this.formField.locator != null) && (response[i] === this.formField.locator.cpf)) {
						this.messages.showErrorMessage(this.$scope, "CPF/CNPJ já informado na proposta");
						this.validated.locatorError = true;
					} else {
						this.validated.locatorError = false;
					}

					if ((this.formField.estateAdmin != null) && (response[i] === this.formField.estateAdmin.cpf)) {
						this.messages.showErrorMessage(this.$scope, "CPF/CNPJ já informado na proposta");
						this.validated.estateAdminError = true;
					} else {
						this.validated.estateAdminError = false;
					}
				}
			}
		});
	}

	checkIfHasUserInfo() {

		let hasUserInfo = false;

		if (this.propostaSetupAccountService.getFromCartaCaucaoLocator()) {
			this.formField.locator = this.propostaSetupAccountService.getNewLocatorInfo();
			hasUserInfo = true;
			// console.log('NEW LOCATOR');
			// console.log(this.formField.locator);
		}

		if (this.propostaSetupAccountService.getFromCartaCaucaoEstateAdmin()) {
			this.formField.estateAdmin = this.propostaSetupAccountService.getNewEstateAdminInfo();
			hasUserInfo = true;
			// console.log('NEW ESTATE ADMIN');
			// console.log(this.formField.estateAdmin);
		}

		//clear info from service
		this.propostaSetupAccountService.setNewEstateAdminInfo(null);
		this.propostaSetupAccountService.setNewLocatorInfo(null);

		return hasUserInfo;
	}

	setFromCartaCaucao(value) {
		this.propostaSetupAccountService.setFromCartaCaucaoLocator(value);
		this.propostaSetupAccountService.setFromCartaCaucaoEstateAdmin(value);
	}

	checkOnLoadRequiredFields() {
		// console.log('checkOnLoadRequiredFields');

		if (this.checkIfHasUserInfo()) {

			this.formField.stepStatusPropostaCaucao = 'Não Iniciado';
		}

		if (!this.isFormPristine() && this.formRequired()) {

			return;
		}

		this.formField.stepStatusPropostaCaucao = 'Incompleto';
	}

	isLocatorDisabled() {

		return this.formField.status && this.formField.status.toLowerCase() != "em andamento";
	}

	isEstateAdminDisabled() {

		return this.formField.status && this.formField.status.toLowerCase() != "em andamento";
	}

	isFormPristine() {

		return this.formField.stepStatusPropostaCaucao === 'Não Iniciado';
	}

	listenValidationRequest() {
		// console.log('listenValidationRequest');

		this.$scope.$on('stepValidate', () => {
			// console.log('listened stepValidate');

			this.$scope.$emit('stepValidateResponse', this.formValidation());
		});
	}

	listenRequiredRequest() {
		// console.log('listenRequiredRequest');

		this.$scope.$on('stepRequired', () => {
			// console.log('listened stepValidate');

			this.$scope.$emit('stepRequiredResponse', this.formRequired());
			console.log('Step Required - Tela Proposta Caucao' - this.formRequired());
		});
	}

	formRequired() {

		let requiredFieldsOk = true;

		this.resetRequiredErrors();

		if (!this.requireLocator()) {
			// console.log('requireLocator');

			requiredFieldsOk = false;
		}

		if (this.formField.estateAdminIsNotLocator == 'true') {
			if (!this.requireEstateAdmin()) {
				// console.log('requireEstateAdmin');

				requiredFieldsOk = false;
			}

			if (!this.requireEstateAdminEmail()) {
				// console.log('requireEstateAdmin');

				requiredFieldsOk = false;
			}
		}

		/*
		if (!this.requireLocatorEmail()) {
			// console.log('requireEstateAdmin');

			requiredFieldsOk = false;
		}

		if (!this.requireLocatorCpfCnpj()) {
			// console.log('requireLocatorCpfCnpj');

			requiredFieldsOk = false;
		}*/

		/*if (!this.requireLocatorName()) {
			// console.log('requireLocatorName');

			requiredFieldsOk = false;
		}*/

		/*if (!this.requirePercentualDireito()) {
			requiredFieldsOk = false;
		}*/

		if (!this.formRequiredAddress()) {
			// console.log('formRequiredAddress');

			requiredFieldsOk = false;
		}

		if (!this.requireEstatePurpose()) {
			// console.log('requireEstatePurpose');

			requiredFieldsOk = false;
		}

		if (!this.requireEstateType()) {
			// console.log('requireEstateType');

			requiredFieldsOk = false;
		}

		// console.log('formRequired', requiredFieldsOk);

		if (requiredFieldsOk) {

			this.formField.stepStatusPropostaCaucao = 'Concluido';
		} else {
			this.formField.stepStatusPropostaCaucao = 'Incompleto';
		}

		return requiredFieldsOk;
	}

	formRequiredAddress() {

		let requiredFieldsOk = true;

		this.resetRequiredAddressErrors();

		if (!this.requireClientCep()) {
			// console.log('requireClientCep');

			requiredFieldsOk = false;
		}

		if (!this.requireClientStreetAddress()) {
			// console.log('requireClientStreetAddress');

			requiredFieldsOk = false;
		}

		if (!this.requireClientStreetNumber()) {
			// console.log('requireClientStreetNumber');

			requiredFieldsOk = false;
		}

		if (!this.requireClientDistrict()) {
			// console.log('requireClientDistrict');

			requiredFieldsOk = false;
		}

		if (!this.requireClientCity()) {
			// console.log('requireClientCity');

			requiredFieldsOk = false;
		}

		if (!this.requireClientUf()) {
			// console.log('requireClientUf');

			requiredFieldsOk = false;
		}

		// console.log('formRequiredAddress', requiredFieldsOk);

		return requiredFieldsOk;
	}

	resetRequiredAddressErrors() {

		this.required.clientCepError = false;
		this.required.clientStreetAddressError = false;
		this.required.clientStreetNumberError = false;
		this.required.clientDistrictError = false;
		this.required.clientCityError = false;
		this.required.clientUfError = false;
	}

	resetRequiredErrors() {

		this.required.locatorError = false;
		this.required.estateAdminError = false;
		this.required.estateAdminEmailError = false;
		this.required.locatorEmailError = false;
		this.required.locatorCpfCnpjError = false;
		this.required.locatorNameError = false;
		this.required.percentualDireitoError = false;
		this.required.estatePurposeError = false;
		this.required.estateTypeError = false;
		this.resetRequiredAddressErrors();
	}

	requireLocator() {

		if (this.hasLocator()) {

			this.required.locatorError = false;
			return true;
		}

		this.required.locatorError = true;
		return false;
	}

	requireEstateAdmin() {

		if (!this.formField.estateAdminIsNotLocator || this.hasEstateAdmin()) {

			this.required.estateAdminError = false;
			return true;
		}

		this.required.estateAdminError = true;
		return false;
	}

	requireLocatorEmail() {
		console.log('entrou 1');

		if (this.hasLocator()) {
			console.log('entrou 2');
			if (this.hasLocatorEmail()) {
				console.log('entrou 3');
				this.required.locatorEmailError = false;
				return true;
			}

			this.required.locatorEmailError = true;
			return false;
		}

	}

	requireEstateAdminEmail() {

		if (this.hasEstateAdmin()) {

			if (this.hasEstateAdminEmail()) {
				this.required.estateAdminEmailError = false;
				return true;
			}

			this.required.estateAdminEmailError = true;
			return false;
		}

	}

	requireLocatorCpfCnpj() {

		if (!this.formField.locatorIsNotClient || this.hasLocatorCpfCnpj()) {

			this.required.locatorCpfCnpjError = false;
			return true;
		}

		this.required.locatorCpfCnpjError = true;
		return false;
	}

	requireLocatorName() {

		if (!this.formField.locatorIsNotClient || this.hasLocatorName()) {

			this.required.locatorNameError = false;
			return true;
		}

		this.required.locatorNameError = true;
		return false;
	}

	requirePercentualDireito() {
		if (!this.formField.locatorIsNotClient || this.hasPercentualDireito()) {

			this.required.percentualDireito = false;
			return true;
		}

		this.required.percentualDireito = true;
		return false;
	}

	requireClientCep() {

		if (this.hasClientCep()) {

			this.required.clientCepError = false;
			return true;
		}

		this.required.clientCepError = true;
		return false;
	}

	requireClientStreetAddress() {

		if (this.hasClientStreetAddress()) {

			this.required.clientStreetAddressError = false;
			return true;
		}

		this.required.clientStreetAddressError = true;
		return false;
	}

	requireClientStreetNumber() {

		if (this.hasClientStreetNumber()) {

			this.required.clientStreetNumberError = false;
			return true;
		}

		this.required.clientStreetNumberError = true;
		return false;
	}

	requireClientDistrict() {

		if (this.hasClientDistrict()) {

			this.required.clientDistrictError = false;
			return true;
		}

		this.required.clientDistrictError = true;
		return false;
	}

	requireClientCity() {

		if (this.hasClientCity()) {

			this.required.clientCityError = false;
			return true;
		}

		this.required.clientCityError = true;
		return false;
	}

	requireClientUf() {

		if (this.hasClientUf()) {

			this.required.clientUfError = false;
			return true;
		}

		this.required.clientUfError = true;
		return false;
	}

	requireEstatePurpose() {

		if (this.hasEstatePurpose()) {

			this.required.estatePurposeError = false;
			return true;
		}

		this.required.estatePurposeError = true;
		return false;
	}

	requireEstateType() {

		if (this.hasEstateType()) {

			this.required.estateTypeError = false;
			return true;
		}

		this.required.estateTypeError = true;
		return false;
	}

	hasLocator() {

		return !!this.formField.locator;
	}

	hasEstateAdmin() {

		return !!this.formField.estateAdmin;
	}

	hasLocatorEmail() {

		return !!this.formField.locator.email;
	}

	hasEstateAdminEmail() {

		return !!this.formField.estateAdmin.email;
	}

	hasLocatorCpfCnpj() {

		return !!this.formField.locatorCpfCnpj;
	}

	hasLocatorName() {

		return !!this.formField.locatorName;
	}

	hasPercentualDireito() {
		return !!this.formField.percentualDireito;
	}

	hasClientCep() {

		return !!this.formField.clientCep;
	}

	hasClientStreetAddress() {

		return !!this.formField.clientStreetAddress;
	}

	hasClientStreetNumber() {

		return !!this.formField.clientStreetNumber;
	}

	hasClientDistrict() {

		return !!this.formField.clientDistrict;
	}

	hasClientCity() {

		return !!this.formField.clientCity;
	}

	hasClientUf() {

		return !!this.formField.clientUf;
	}

	hasEstatePurpose() {

		return !!this.formField.estatePurpose;
	}

	hasEstateType() {

		return !!this.formField.estateType;
	}

	formValidation() {
		// console.log('formValidation');

		let validFieldsOk = true;

		this.resetValidErrors();

		if (!this.isvalidEstateAdmin()) {
			validFieldsOk = false;
		}

		if (!this.isValidLocator()) {
			validFieldsOk = false;
		}

		if (!this.isValidLocatorCpfCnpj()) {

			validFieldsOk = false;
		}

		if (!this.isValidLocatorName()) {

			validFieldsOk = false;
		}

		if (!this.isValidClientCep()) {

			validFieldsOk = false;
		}

		if (!this.isValidAddress()) {
			validFieldsOk = false;
		}

		if (!this.isValidCity()) {
			validFieldsOk = false;
		}

		if (!this.isValidDistrict()) {
			validFieldsOk = false;
		}

		return validFieldsOk;
	}

	resetValidField(fieldName) {

		this.validated[`${fieldName}Error`] = false;
	}

	resetValidErrors() {

		this.validated.locatorCpfCnpjError = false;
		this.validated.locatorNameError = false;
		this.validated.clientCepError = false;
		this.validated.locatorError = false;
		this.validated.estateAdminError = false;
	}

	isvalidEstateAdmin() {
		this.validateDuplicatedCpf();
		if (!this.formField.estateAdminIsNotLocator) {
			return true;
		} else if (this.formField.estateAdminIsNotLocator && (!this.hasEstateAdmin || !this.validated.estateAdminError)) {
			return true;
		}

		return false;
	}

	isLocatorPF() {
		return this.formField.locator.isPersonAccount === true;
	}

	isAdminPF() {
		return this.formField.estateAdmin.isPersonAccount === true;
	}

	isValidCity() {
		if (!this.hasClientCity() || this.valid.isValidCity(this.formField.clientCity)) {
			this.validated.clientCityError = false;
			return true;
		}

		this.validated.clientCityError = true;
		return false;
	}

	isValidDistrict() {
		if (!this.hasClientDistrict() || this.valid.isValidDistrict(this.formField.clientDistrict)) {
			this.validated.clientDistrictError = false;
			return true;
		}

		this.validated.clientDistrictError = true;
		return false;
	}

	isValidAddress() {
		if (!this.hasClientStreetAddress() || this.valid.isValidAddress(this.formField.clientStreetAddress)) {
			this.validated.clientStreetAddressError = false;
			return true;
		}

		this.validated.clientStreetAddressError = true;
		return false;
	}

	isValidClientCep() {
		console.log('isValidClientCep', this.hasClientCep());

		if ((!this.hasClientCep() || this.valid.isValidCep(this.formField.clientCep)) && !this.warning.clientCepError.show) {

			this.validated.clientCepError = false;
			return true;
		}

		this.validated.clientCepError = true;
		return false;
	}

	isValidLocator() {
		this.validateDuplicatedCpf();
		if (!this.hasLocator() || !this.validated.locatorError) {

			return true;
		}

		return false;
	}

	isValidLocatorCpfCnpj() {

		if (!this.hasLocatorCpfCnpj() || this.valid.isValidCnpj(this.formField.locatorCpfCnpj) || this.valid.isValidCpf(this.formField.locatorCpfCnpj)) {

			this.validated.locatorCpfCnpjError = false;
			return true;
		}

		this.validated.locatorCpfCnpjError = true;
		return false;
	}

	isValidLocatorName() {

		if (!this.hasLocatorName() || this.valid.isValidName(this.formField.locatorName)) {

			this.validated.locatorNameError = false;
			return true;
		}

		this.validated.locatorNameError = true;
		return false;
	}

	hasErrorEstateAdmin() {
		return this.validated.estateAdminError || this.required.estateAdminError;
	}

	hasErrorLocator() {
		return this.validated.locatorError || this.required.locatorError;
	}

	hasErrorLocatorCpfCnpjError() {

		return this.validated.locatorCpfCnpjError || this.required.locatorCpfCnpjError;
	}

	hasErrorLocatorName() {

		return this.validated.locatorNameError || this.required.locatorNameError;
	}

	hasErrorPercentualDireito() {

		return this.validated.percentualDireitoError || this.required.percentualDireitoError;
	}

	hasErrorClientCep() {

		return this.validated.clientCepError || this.required.clientCepError;
	}

	hasErrorClientAddress() {

		return this.validated.clientStreetAddressError || this.required.clientStreetAddressError;
	}

	hasErrorClientCity() {

		return this.validated.clientCityError || this.required.clientCityError;
	}

	hasErrorClientDistrict() {

		return this.validated.clientDistrictError || this.required.clientDistrictError;
	}

	isPropostaDuplaGarantia() {
		const propostaType = sessionStorage.getItem("tipo_lista");
		if (propostaType == 'D') {
			return true;
		} else {
			return false;
		}
	}

	searchLocator(searchString) {
		// console.log('searchLocator', this);

		const propostaCaucao = this.$parent.propostaCaucao;

		return propostaCaucao.propostaCaucaoApi.searchLocator(searchString);
	}

	searchEstateAdmin(searchString) {
		// console.log('searchEstateAdmin', this);

		const propostaCaucao = this.$parent.propostaCaucao;

		return propostaCaucao.propostaCaucaoApi.searchEstateAdmin(searchString);
	}

	searchLocatorFocusOut() {
		// console.log('searchLocatorFocusOut', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {

			if (!this.autoComplete.hasValidInput(this.formField.locator)) {

				this.autoComplete.clearInput(this.$scope, 'searchLocator');
			}
		}, 100);
		this.validateDuplicatedCpf();
	}

	searchEstateAdminFocusOut() {
		// console.log('searchEstateAdminFocusOut', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {

			if (!this.autoComplete.hasValidInput(this.formField.estateAdmin)) {

				this.autoComplete.clearInput(this.$scope, 'searchEstateAdmin');
			}
		}, 100);
		this.validateDuplicatedCpf();
	}

	searchLocatorSelect(item) {
		// console.log('searchLocatorSelect', item);

		if (!item) { return; }

		const propostaCaucao = this.$parent.propostaCaucao;
		const hasAction = !!item.originalObject.action;

		if (hasAction) {

			const action = item.originalObject.action;

			propostaCaucao[action](item);

			return;
		}

		propostaCaucao.formField.locator = item.originalObject;
		propostaCaucao.requireLocator();

		propostaCaucao.messages.cleanMessages(propostaCaucao.$scope);
		propostaCaucao.validateDuplicatedCpf();

		console.log('Locator: ', propostaCaucao.formField.locator);
	}

	searchEstateAdminSelect(item) {
		// console.log('searchEstateAdmin', item);

		if (!item) { return; }

		const propostaCaucao = this.$parent.propostaCaucao;
		const hasAction = !!item.originalObject.action;

		if (hasAction) {

			const action = item.originalObject.action;

			propostaCaucao[action](item);

			return;
		}

		propostaCaucao.formField.estateAdmin = item.originalObject;
		propostaCaucao.requireEstateAdmin();

		propostaCaucao.messages.cleanMessages(propostaCaucao.$scope);
		propostaCaucao.validateDuplicatedCpf();
		//if (!propostaCaucao.accountLocatorAdminHelper.isAccountLocatorAdminValid(propostaCaucao.formField)) {
		//	propostaCaucao.messages.showErrorMessage(propostaCaucao.$scope, "CPF/CNPJ já informado na proposta");
		//	propostaCaucao.validated.estateAdminError = true;
		//} else {
		//	propostaCaucao.validated.estateAdminError = false;
		//}
		console.log('Admin: ', propostaCaucao.formField.estateAdmin);

	}

	searchLocatorChanged() {
		// console.log('searchLocatorChanged', this);
		const propostaCaucao = this.$parent.propostaCaucao;

		const valor = $('#searchLocator input').val();
		if (valor === "") propostaCaucao.messages.cleanMessages(propostaCaucao.$scope);

		propostaCaucao.autoComplete.resetSelected(propostaCaucao.formField, 'locator');
	}

	searchEstateAdminChanged() {
		// console.log('searchEstateAdminChanged', this);

		const propostaCaucao = this.$parent.propostaCaucao;

		const valor = $('#searchEstateAdmin input').val();
		if (valor === "") propostaCaucao.messages.cleanMessages(propostaCaucao.$scope);

		propostaCaucao.autoComplete.resetSelected(propostaCaucao.formField, 'estateAdmin');

	}

	getClientAddress() {

		if (!this.formField.useClientAddress) { return; }

		const clientId = this.formField.account.Id;

		this.loaderClientAddress = true;

		this.propostaCaucaoApi.getClientAddress(clientId).then((data) => {

			angular.extend(this.formField, data.addressAccount);

			this.loaderClientAddress = false;

			this.formRequiredAddress();
		});
	}

	createLocatorModal() {
		this.searchLocatorFocusOut();
		const parentElem = angular.element(document.querySelector('.col-lg-4'));
		this.$uibModal.open({
			template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromCartaCaucaoLocator(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div><div class="col-lg-6"><a class="box-modal pull-left" href="" ui-sref="new-juridical-account" ng-click="modalController.setFromCartaCaucaoLocator(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePJAccountPath}}"/><p>Pessoa Jurídica</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
			controller: 'accountTypeModalController',
			controllerAs: 'modalController',
			appendTo: parentElem
		});
	}

	createEstateAdminModal() {
		this.searchEstateAdminFocusOut();
		const parentElem = angular.element(document.querySelector('.col-lg-4'));
		this.$uibModal.open({
			template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromCartaCaucaoEstateAdmin(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div><div class="col-lg-6"><a class="box-modal pull-left" href="" ui-sref="new-juridical-account" ng-click="modalController.setFromCartaCaucaoEstateAdmin(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePJAccountPath}}"/><p>Pessoa Jurídica</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
			controller: 'accountTypeModalController',
			controllerAs: 'modalController',
			appendTo: parentElem
		});
	}

	getAddressFromCEP() {
		const me = this;
		const cep = me.formField.clientCep;

		if ((cep.length == 9) && (cep.indexOf("-") == 5)) {

			this.$scope.$emit('changeMainShowLoading', true);
			this.cepHelper.getAddressFromCEP(cep, (response) => {

				me.messages.cleanMessages(me.$scope);

				me.formField.clientStreetAddress = response.logradouro;
				me.formField.clientDistrict = response.bairro;
				me.formField.clientCity = response.cidade;
				me.formField.clientUf = response.uf;

				if (!response.isSuccessful) {

					if (me.cepHelper.remoteCallErrorLimitWasExceded()) {
						me.warning.clientCepError.show = false;
						me.warning.clientCepError.value = null;
						me.messages.showErrorMessage(me.$scope, me.cepHelper.remoteCallErrorMessage);
					} else {
						me.warning.clientCepError.show = true;
						me.warning.clientCepError.value = response.errorMessages[0];
					}
					me.cepHelper.setPristine();
				} else {
					me.formControl.requireProperty('clientStreetAddress', me.required);
					me.formControl.requireProperty('clientDistrict', me.required);
					me.formControl.requireProperty('clientCity', me.required);
					me.formControl.requireProperty('clientUf', me.required);
					//this.validated.clientCepError = false;
					me.warning.clientCepError.show = false;
					me.warning.clientCepError.value = null;
					me.messages.showErrorMessage(me.$scope, '');
				}

				this.$scope.$emit('changeMainShowLoading', false);
			});
		} else {
			me.cepHelper.setPristine();
			me.formField.clientStreetAddress = null;
			me.formField.clientDistrict = null;
			me.formField.clientCity = null;
			me.formField.clientUf = null;
		}
	}

	consultarCrivo() {
		const me = this;
		const cpfCnpj = me.formField.locatorCpfCnpj;

		const isPersonal = this.accountUtilService.isValidCpf(cpfCnpj);
		const isCompany = this.accountUtilService.isValidCnpj(cpfCnpj);

		if (isPersonal || isCompany) {
			me.$scope.$emit('changeMainShowLoading', true);

			grecaptcha.ready(function () {
				grecaptcha.execute('6Ld7HnMaAAAAALsutf2GlIhn9Qb-reY-1ir-38uE', { action: 'submit' }).then(function (token) {

					console.log('---- 2. GTOKEN: ', token);

					me.crivoHelper.consultarCrivo(cpfCnpj, token, (response) => {

						if (!response.isSuccessful) {
							me.messages.showErrorMessage(me.$scope, me.crivoHelper.remoteCallErrorMessage);
							me.crivoHelper.setPristine();
						} else {
							me.formField.locatorName = (isPersonal ? response.req_nome : response.req_razaoSocial);
							me.messages.showErrorMessage(me.$scope, '');
						}

						me.$scope.$emit('changeMainShowLoading', false);

					});

				});
			});

		} else {
			me.crivoHelper.setPristine();
			me.formField.locatorName = null;
			me.messages.showErrorMessage(me.$scope, '');
		}
	}

}

module.exports = propostaCaucaoController;
