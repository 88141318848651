'use strict';

class propostaController {
	// @ngInject
	constructor(
		$compile,
		$scope,
		$state,
		$window,
		sfConfig,
		formControlService,
		propostaApi,
		resgateService,
		breadcrumbHelper,
		utilsHelper,
		propostaBoletoService,
		dadosBancariosHelper,
		propostaPaymentService
	) {
		this.resgateService = resgateService;
		this.steps = [
			'proposta.new',
			'proposta.information',
			'proposta.caucao',
			'proposta.payment',
			'proposta.generate',
			'proposta.boleto'
			/*,
						'proposta.file'*/
		];

		this.propostaBoletoApi = propostaBoletoService;
		this.propostaPaymentService = propostaPaymentService;
		this.$compile = $compile;
		this.formControl = formControlService;
		this.sfResource = sfConfig.resource;
		this.$scope = $scope;
		this.$state = $state;
		this.$window = $window;
		this.propostaApi = propostaApi;
		this.utilsHelper = utilsHelper;
		this.dadosBancariosHelper = dadosBancariosHelper;

		this.isValidStep = null;
		this._numberProposta = null;
		this._hasPrinted = false;

		this.$scope.breadcrumb = breadcrumbHelper;

		this.listenValidationResponse();
		this.listenRequiredFieldsResponse();
		this.listenClearMessages();
		this.listenDuplicatedCpfValidation();

		// setInterval(() => { console.log(this.formControl.field); }, 15000);

		this.$scope.canCreateProposta = false;
		this.listenToCanCreateProposta();
		//this.$scope.canCreateProposta = true;
	}

	listenToCanCreateProposta() {
		this.$scope.$on('checkIfCanCreateProposta', (event, paCanCreateProposta) => {
			event.stopPropagation();
			console.log('>>>listenToCanCreateProposta: ', paCanCreateProposta);
			this.$scope.canCreateProposta = paCanCreateProposta;
		});
	}

	listenDuplicatedCpfValidation() {
		this.$scope.$on('duplicatedCpfValidation', (event, obj) => {
			//console.log('escutando duplicatedCpfValidation');
			event.stopPropagation();

			const listCpf = [];
			if (obj.account !== null && !!obj.account.cpf) listCpf.push(obj.account.cpf);
			if (obj.locator !== null && !!obj.locator.cpf) listCpf.push(obj.locator.cpf);
			if (obj.estateAdmin !== null && !!obj.estateAdmin.cpf) listCpf.push(obj.estateAdmin.cpf);

			const duplicatedValue = this.utilsHelper.getRepeatedElements(listCpf);
			//console.log('broadcasting duplicatedCpfValidationResponse');
			this.$scope.$broadcast('duplicatedCpfValidationResponse', duplicatedValue);
		});
	}

	listenValidationResponse() {
		// console.log('listenValidationResponse');

		this.$scope.$on('stepValidateResponse', (event, isValid) => {
			// console.log('listened stepValidateResponse', event, isValid);

			event.stopPropagation && event.stopPropagation();

			this.isValidStep = isValid;
		});
	}

	listenRequiredFieldsResponse() {
		// console.log('listenRequiredFieldsResponse');

		this.$scope.$on('stepRequiredResponse', (event, hasRequiredFields) => {
			console.log('listened stepRequiredResponse', event, hasRequiredFields);

			event.stopPropagation();

			this.hasRequiredFieldsStep = hasRequiredFields;
		});
	}

	listenClearMessages() {
		this.$scope.$on('clearMessages', (event) => {
			event.stopPropagation();

			this.cleanMessages();
		});
	}

	nextStep() {
		// console.log('nextStep', this.hasAccount(), this.validateStep(), this.isNextStepDisabled(), this.hasNextStep());

		this.$scope.$broadcast('nextStep');

		this.cleanMessages();

		if (!this.hasAccount() || !this.validateStep() || this.isNextStepDisabled()) {
			return;
		}

		if (this.checkIfAccountAgeHasError()) {
			$('#warningAccountAgeError').modal('show');
			return;
		}

		this.requiredFieldsStep();

		this.$window.scrollTo(0, 0);

		this.hasNextStep() && this.$state.go(this.nextStepName());
	}

	editBestDayDebit() {
		this.$scope.$broadcast('bestDayDebit');
	}

	nextStepName() {
		// console.log('nextStepName', this.nextStepIndex());

		// if (!this.hasNextStep()) { return; }

		return this.steps[this.nextStepIndex()];
	}

	hasCancel() {
		return this.formControl.actions.hasCancel && this.formControl.field.status != 'Concluído' && this.formControl.field.formStep != 'proposta.boleto';
	}

	hasNextStep() {
		// console.log('hasNextStep');
		let hasNextStep = true;

		if (this.nextStepIndex() >= this.steps.length) {
			return false;
		}

		if (this.nextStepName() === 'proposta.boleto' && !this.formControl.field.numberProposta) {
			hasNextStep = false;
		}

		return hasNextStep;
	}

	isNextStepDisabled() {
		// console.log('isNextStepDisabled', this.hasAccount(), this.billetHasPrinted());

		return (
			!this.hasAccount() ||
			//this.checkIfAccountAgeHasError() ||
			this.checkRequiredDgField()
		);
	}

	checkRequiredDgField() {
		if (
			(this.formControl.field.formStep == 'proposta.information' ||
				this.formControl.field.formStep == 'proposta.caucao' ||
				this.formControl.field.formStep == 'proposta.payment' ||
				this.formControl.field.formStep == 'proposta.generate' ||
				this.formControl.field.formStep == 'proposta.boleto') &&
			this.isPropostaDuplaGarantia()
		) {
			if (
				this.formControl.field.DG.brokerAccount == null ||
				this.formControl.field.DG.brokerAccount == '' ||
				this.formControl.field.DG.lifeTermValue == 0 ||
				this.formControl.field.DG.lifeTermValue == null
			) {
				return true;
			}
		} else {
			return false;
		}
	}

	// billetHasPrinted() {
	// 	// console.log('billetHasPrinted', this.formControl.field.formStep, this.hasPrinted);

	// 	if (this.formControl.field.formStep !== 'proposta.boleto') {

	// 		return true;
	// 	}

	// 	return this.hasPrinted;
	// }

	nextStepIndex() {
		// console.log('nextStepIndex');

		return this.steps.indexOf(this.formControl.field.formStep) + 1;
	}

	prevStep() {
		// console.log('prevStep');

		this.cleanMessages();

		if (!this.validateStep()) {
			return;
		}

		this.requiredFieldsStep();

		this.$window.scrollTo(0, 0);

		this.hasPrevStep() && this.$state.go(this.prevStepName());
	}

	prevStepName() {
		// console.log('prevStepName', this.prevStepIndex());

		return this.steps[this.prevStepIndex()];
	}

	hasPrevStep() {
		// console.log('hasPrevStep');
		let hasNextStep = true;

		if (this.prevStepIndex() < 0) {
			return false;
		}

		if (this.prevStepName() === 'proposta.generate' && this.formControl.field.numberProposta) {
			hasNextStep = false;
		}

		return hasNextStep;
	}

	prevStepIndex() {
		// console.log('prevStepIndex');

		return this.steps.indexOf(this.formControl.field.formStep) - 1;
	}

	cleanMessages() {
		this.saveMsgError = false;
		this.saveMsgSuccess = false;
		this.generateMsgSuccess = false;
		this.generateMsgError = false;
	}

	hasAccount() {
		return !!this.formControl.field.account;
	}
	hasStatusBtn() {
		console.log('Status proposta', this.formControl.field.status);
		if (this.formControl.field.status == 'Em andamento' || this.formControl.field.status == null) {
			return true;
		}
	}

	hasApj() {
		return this.formControl.validateApj();
	}

	validateStep() {
		this.$scope.$broadcast('stepValidate');

		console.log('validateStep resultado - ', this.isValidStep);

		return this.isValidStep;
	}

	requiredFieldsStep() {
		// console.log('requiredFieldsStep');

		this.$scope.$broadcast('stepRequired');

		return this.hasRequiredFieldsStep;
	}

	saveCurrentProposta() {
		// console.log('saveCurrentProposta', this.validateStep());

		this.cleanMessages();

		if (!this.hasAccount() || !this.hasApj() || !this.validateStep() || !this.hasStatusBtn()) {
			// console.log('feedback');

			return;
		}

		// console.log('salvar');

		this.$scope.$emit('changeMainShowLoading', true);
		this.propostaApi.saveProposta(this.formControl.field).then((response) => {
			// console.log('SALVANDO response', response);

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {
				this.apiUnsuccessful(response);

				return;
			}

			this.formControl.field.Id = response.propostaEdit.Id;

			this.saveMsgError = false;
			this.saveMsgSuccess = true;
			this.$window.scrollTo(0, 0);
		});
	}

	isPropostaSaved() {
		return !!this.formControl.field.Id;
	}

	cancelCurrentProposta() {
		// console.log('cancelCurrentProposta');

		this.cleanMessages();

		if (this.formControl.field.Id) {
			this.$scope.$emit('changeMainShowLoading', true);
			this.propostaApi.cancelProposta(this.formControl.field.Id).then((response) => {
				// console.log('cancel response', response);

				this.$scope.$emit('changeMainShowLoading', false);

				if (!response.isSuccessful) {
					this.apiUnsuccessful(response);

					return;
				}

				this.$window.scrollTo(0, 0);
				this.formControl.reset();
				this.$state.go('proposta.list');
			});

			return;
		}

		this.formControl.reset();
		if (this.resgateService.getPropostaFromResgate() === true) {
			this.$state.go('resgate.type');
		} else {
			this.$state.go('proposta.list');
		}
	}

	editProposta(propostaId) {
		// console.log('editProposta', propostaId);
		this.cleanMessages();

		this.$scope.$emit('changeMainShowLoading', true);
		this.propostaApi.getProposta(propostaId).then((response) => {
			// console.log('getProposta response', response);

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {
				this.apiUnsuccessful(response);

				return;
			}

			this.formControl.feedNewData(response.propostaEdit);
			this.$window.scrollTo(0, 0);
			this.$state.go('proposta.new');
		});
	}

	apiUnsuccessful(response) {
		this.saveMsgError = angular.toJson(response.errorMessages);
		this.saveMsgSuccess = false;
		this.$window.scrollTo(0, 0);
	}

	navStepStatus(state) {
		// console.log('navStepStatus', state, this.formControl.field.formStep);

		let stepStatus = '';

		if (state === this.formControl.field.formStep) {
			// console.log('navStepStatus formStep:', state, this.formControl.field.formStep);

			return 'act';
		}

		switch (true) {
		case state === 'proposta.new':
			stepStatus = this.formControl.field.stepStatusPropostaNew;

			if (stepStatus !== 'Não Iniciado') {
				return 'edt';
			}

			case state === 'proposta.information':
				stepStatus = this.formControl.field.stepStatusPropostaInformation;

				if (stepStatus !== 'Não Iniciado') {
					return 'edt';
				}

				case state === 'proposta.payment':
					stepStatus = this.formControl.field.stepStatusPropostaPayment;

					if (stepStatus !== 'Não Iniciado') {
						return 'edt';
					}

					case state === 'proposta.caucao':
						stepStatus = this.formControl.field.stepStatusPropostaCaucao;

						if (stepStatus !== 'Não Iniciado') {
							return 'edt';
						}

						case state === 'proposta.generate':
							stepStatus = this.formControl.field.stepStatusPropostaGenerate;

							if (stepStatus !== 'Não Iniciado') {
								return 'edt';
							}

							case state === 'proposta.boleto':
								// stepStatus = this.formControl.field.formStep;

								// if (stepStatus === 'proposta.file') {

								// 	return 'edt';
								// }

								stepStatus = this.formControl.field.stepStatusPropostaBoleto;

								if (stepStatus !== 'Não Iniciado') {
									return 'edt';
								}

								default:
									return;
		}
	}

	navStepCompleteness(state) {
		// console.log('navStepCompleteness', state);

		let stepCompletenessClass = 'hide';

		const warningClass = 'fa-exclamation-triangle warning';
		const successClass = 'fa-check-circle success';

		if (state === 'proposta.new') {
			if (this.formControl.field.stepStatusPropostaNew === 'Incompleto') {
				stepCompletenessClass = warningClass;
			}

			if (this.formControl.field.stepStatusPropostaNew === 'Concluido') {
				stepCompletenessClass = successClass;
			}
		}

		if (state === 'proposta.information') {
			if (this.formControl.field.stepStatusPropostaInformation === 'Incompleto') {
				stepCompletenessClass = warningClass;
			}

			if (this.formControl.field.stepStatusPropostaInformation === 'Concluido') {
				stepCompletenessClass = successClass;
			}
		}

		if (state === 'proposta.caucao') {
			if (this.formControl.field.stepStatusPropostaCaucao === 'Incompleto') {
				stepCompletenessClass = warningClass;
			}

			if (this.formControl.field.stepStatusPropostaCaucao === 'Concluido') {
				stepCompletenessClass = successClass;
			}
		}

		if (state === 'proposta.payment') {
			if (this.formControl.field.stepStatusPropostaPayment === 'Incompleto') {
				stepCompletenessClass = warningClass;
			}

			if (this.formControl.field.stepStatusPropostaPayment === 'Concluido') {
				stepCompletenessClass = successClass;
			}
		}

		if (state === 'proposta.generate') {
			if (this.formControl.field.stepStatusPropostaGenerate === 'Incompleto') {
				stepCompletenessClass = warningClass;
			}

			if (this.formControl.field.stepStatusPropostaGenerate === 'Concluido') {
				stepCompletenessClass = successClass;
			}
		}

		if (state === 'proposta.boleto') {
			if (this.formControl.field.stepStatusPropostaBoleto === 'Incompleto') {
				stepCompletenessClass = warningClass;
			}

			if (this.formControl.field.stepStatusPropostaBoleto === 'Concluido') {
				stepCompletenessClass = successClass;
			}
		}

		// console.log('class: ', stepCompletenessClass);
		return stepCompletenessClass;
	}

	navStepGoTo(state) {
		// console.log('navStepGoTo', state);
		// console.log(this.formControl.field.status);

		this.cleanMessages();

		if (this.isStepBeforeGenerate(state) === this.isPropostaGenerated() && (!this.formControl.field.status || this.formControl.field.status === 'Em andamento')) {
			return;
		}

		if (!this.hasAccount() || !this.validateStep()) {
			return;
		}

		//Aplica somente para DG
		if (state != 'proposta.information' && state != 'proposta.new' && this.checkRequiredDgField()) {
			return;
		}

		if (this.checkIfAccountAgeHasError()) {
			$('#warningAccountAgeError').modal('show');
			return;
		}

		// console.log('navStepGoTo going');

		this.requiredFieldsStep();

		this.$window.scrollTo(0, 0);

		this.$state.go(state);
	}

	isStepBeforeGenerate(state) {
		return this.steps.indexOf(state) < this.steps.indexOf('proposta.boleto');
	}

	checkIfAccountAgeHasError() {
		console.log('account em checkIfAccountAgeHasError', this.formControl.field.account);

		if (this.formControl.field.account.age == null) {
			console.log('idade nula, calculando atraves da data de nascimento', this.formControl.field.account.birthDate);

			// Exemplo de cálculo da idade com base na data de nascimento
			const birthDate = new Date(this.formControl.field.account.birthDate); // Supondo que birthDate seja a data de nascimento
			const today = new Date();
			let age = today.getFullYear() - birthDate.getFullYear();
			const monthDiff = today.getMonth() - birthDate.getMonth();

			if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}

			// Atribui a idade ao campo age
			this.formControl.field.account.age = age;
			console.log('data calculada', age);
		}

		if (this.formControl.field.account.age != null && this.isPropostaDuplaGarantia()) {
			const accountAge = parseInt(this.formControl.field.account.age);

			if (accountAge > 65 || accountAge < 18) {
				return true;
			} else {
				return false;
			}
		}

		console.log('Cliente sem idade informada');
	}

	isPropostaGenerated() {
		return !!this.formControl.field.numberProposta;
	}

	hasNavNewProposta() {
		// console.log('hasNavButtons', this.$state.current.name);

		return this.$state.current.name !== 'proposta.list';
	}

	isGenerateStep() {
		return this.formControl.field.formStep === 'proposta.generate';
	}

	isStep(step) {
		return this.formControl.field.formStep === step;
	}

	/*--------------------------------------------------------------------------------		
	>>>> Daniel Correa
		JIRA:CAP-2703: metodo copiado de CI pois estava com bug. Ao atualizar o metodo esse bug foi resolvido		
	--------------------------------------------------------------------------------*/
	generateProposta() {
		// console.log('generateProposta');

		this.cleanMessages();

		if (!this.isPropostaValidated()) {
			this.$window.scrollTo(0, 0);

			return;
		}

		if (this.formControl.field.numberProposta) {
			return;
		}
		this.$scope.$emit('changeMainShowLoading', true);
		//this.propostaApi.saveProposta(this.formControl.field).then((response) => {

		//if (!response.isSuccessful) { return };
		//this.formControl.field.Id = response.propostaEdit.Id;
		// console.log('PROPOSTA');
		// console.log(this.formControl.field);

		this.propostaApi.generateProposta(this.formControl.field).then((response) => {
			if (!response.isSuccessful) {
				this.propostaApi.saveProposta(this.formControl.field).then((response) => {
					if (!response.isSuccessful) {
						this.$scope.$emit('changeMainShowLoading', false);
						this.apiUnsuccessful(response);
						return;
					}
					this.formControl.field.Id = response.propostaEdit.Id;
				});
				this.$scope.$emit('changeMainShowLoading', false);
				this.apiUnsuccessful(response);
				this.generateMsgSuccess = false;
				this.$window.scrollTo(0, 0);
				return;
			}

			this.formControl.field.Id = response.propostaConcluida.Id;
			this.formControl.field.numberProposta = response.propostaConcluida.numberProposta;
			this.formControl.field.DG.numberProposta = response.propostaConcluida.dgNumberProposta;
			this.formControl.field.status = 'Concluído';
			this.formControl.field.stepStatusPropostaBoleto = 'Incompleto';

			this.saveMsgError = false;
			this.generateMsgError = false;
			this.generateMsgSuccess = true;

			const isNotDebit = this.formControl.field.paymentType != 'Débito em Conta Corrente';
			const isNotCredit = this.formControl.field.paymentType != 'CartaoCredito';

			if (this.formControl.field.isOrigemResgate) {
				// CAP-2909 Resgate com recuperação de crédito para Proposta não precisa gerar Boleto
				this.$scope.$emit('changeMainShowLoading', false);
				this.nextStep();
			} else {
				//--------- CAP-3041 -inicio: gerar o boleto pela primeira vez para forçar segunda via quando imprimir boleto (bug SF)
				if (isNotDebit && isNotCredit) {
					this.propostaBoletoApi.getBillet(this.formControl.field.Id).then((response) => {
						this.$scope.$emit('changeMainShowLoading', false);
						if (!response.isSuccessful) {
							this.apiUnsuccessful(response);
							this.generateMsgSuccess = false;
							this.$window.scrollTo(0, 0);
							return;
						}
					});
				} else if (!isNotCredit) {
					this.propostaApi.generateURLCreditCard(this.formControl.field.Id).then((response) => {
						console.log('resultado da url gerada do cartao: ', response);
					});

					this.$scope.$emit('changeMainShowLoading', false);
				} else {
					this.$scope.$emit('changeMainShowLoading', false);
				}
				//--------- CAP-3041 -fim
			}
			this.propostaApi.generatePropostaForm(this.formControl.field.Id).then(() => {
				this.nextStep();
			});
		});
		//});
	}

	isPropostaDuplaGarantia() {
		const propostaType = sessionStorage.getItem('tipo_lista');
		if (propostaType == 'D') {
			return true;
		} else {
			return false;
		}
	}

	isValidAccountFields() {
		const form = this.formControl.field;

		console.log('Entrou no valida titular generate proposta');
		console.log('Forms validado: ', form);

		if (form.account.isPersonAccount) {
			if (!!form.account.birthCountry == false) {
				console.log('birthCountry obrigatório - Generate Step account');
				return false;
			}

			if (!!form.account.nationality == false) {
				console.log('nationality obrigatório - Generate Step account');
				return false;
			}

			if (form.account.secNac == 'true') {
				if (!!form.account.secondNationality == false) {
					console.log('secondNationality obrigatório - Generate Step account');
					return false;
				}
			}

			return true;
		}

		return true;
	}

	isValidSubscriberFields() {
		const form = this.formControl.field;

		console.log('Entrou no valida subscriber generate proposta');
		console.log('Forms validado: ', form);
		if (form.differentFinancialResponsible == 'true') {
			if (!this.hasField(form.subscriber)) {
				console.log('Subscriber obrigatório - Generate Step');
				return false;
			} else {
				if (form.subscriber.isPersonAccount) {
					if (!!form.degreeOfKinship == false) {
						console.log('degreeOfKinship obrigatório - Generate Step');
						return false;
					}

					if (!!form.subscriber.birthCountry == false) {
						console.log('birthCountry obrigatório - Generate Step');
						return false;
					}

					if (!!form.subscriber.nationality == false) {
						console.log('nationality obrigatório - Generate Step');
						return false;
					}

					if (form.subscriber.secNac == 'true') {
						if (!!form.subscriber.secondNationality == false) {
							console.log('secondNationality obrigatório - Generate Step');
							return false;
						}
					}

					if (!this.hasField(form.subscriber.profession)) {
						console.log('profession obrigatório - Generate Step');
						return false;
					}

					if (!!form.subscriber.declarationCode == false) {
						console.log('declarationCode obrigatório - Generate Step');
						return false;
					}

					if (!!form.subscriber.resourceOrigin == false) {
						console.log('resourceOrigin obrigatório - Generate Step');
						return false;
					} else {
						if (this.isPropostaDuplaGarantia()) {
							if (form.subscriber.resourceOrigin == 'Aposentadoria' && !!form.subscriber.retirementType == false) {
								console.log('Aposentadoria obrigatória. ');
								return false;
							}
						}
					}

					return true;
				} else {
					if (!this.hasField(form.subscriber.profession)) {
						console.log('profession obrigatório - Generate Step');
						return false;
					}

					if (!!form.subscriber.declarationCode == false) {
						console.log('declarationCode obrigatório - Generate Step');
						return false;
					}

					if (!!form.subscriber.resourceOrigin == false) {
						console.log('resourceOrigin obrigatório - Generate Step');
						return false;
					} else {
						if (this.isPropostaDuplaGarantia()) {
							if (form.subscriber.resourceOrigin == 'Aposentadoria' && (form.subscriber.retirementType == '' || form.subscriber.retirementType == null)) {
								console.log('Aposentadoria obrigatória. ');
								return false;
							}
						}
					}

					return true;
				}
			}
		} else {
			return true;
		}
	}

	isPropostaValidated() {
		let validated = false;

		if (this.isGenerateStep()) {
			validated = this.requiredFieldsStep();

			//Valida campos herdados DG
			if (this.isPropostaDuplaGarantia()) {
				if (this.formControl.field.estateAdminIsNotLocator && this.formControl.field.estateAdmin != null) {
					if (this.formControl.field.estateAdmin.email == null || this.formControl.field.estateAdmin.email == '') {
						validated = false;
					}
				}
				if (this.formControl.field.locator != null) {
					if (this.formControl.field.locator.email == null || this.formControl.field.locator.email == '') {
						validated = false;
					}
				}
				if (this.formControl.field.account != null) {
					if (this.formControl.field.account.email == null || this.formControl.field.account.email == '') {
						validated = false;
					}
				}
				if (this.formControl.field.resourceOrigin == 'Aposentadoria' && (this.formControl.field.retirementType == null || this.formControl.field.retirementType == '')) {
					validated = false;
				}
			}

			//Valida campos herdados do Titular IGA e DG
			validated = validated ? this.isValidAccountFields() : validated;
			console.log('PROPOSTA VALIDADA - TITULAR - ', validated);

			//Valida campos herdados do Subscritor IGA e DG
			validated = validated ? this.isValidSubscriberFields() : validated;
			console.log('PROPOSTA VALIDADA - SUBBSCRITOR', validated);

			this.generateMsgError = !validated;
		}

		return validated;
	}

	hasField(field) {
		return typeof field !== 'undefined' && field != null && field != '';
	}

	set numberProposta(number) {
		this._numberProposta = number;
	}

	get numberProposta() {
		return this._numberProposta;
	}

	set hasPrinted(status) {
		// console.log('hasPrinted set', status);

		this.propostaApi.adviseHasPrinted(this.formControl.field.Id);
		this.formControl.field.stepStatusPropostaBoleto = 'Concluido';

		this._hasPrinted = status;
	}

	get hasPrinted() {
		// console.log('hasPrinted get', this._hasPrinted);

		return this._hasPrinted;
	}

	// setBilletPrinted(status) {
	// 	// console.log('setBilletPrinted', status);

	// 	this.propostaApi.adviseHasPrinted(this.formControl.field.Id);

	// 	this._hasPrinted = status;
	// }

	hasSave() {
		return this.isStepBeforeGenerate(this.formControl.field.formStep);
	}

	isConcluded() {
		return this.formControl.field.stepStatusPropostaBoleto === 'Concluido';
	}

	afterCompletion() {
		if (this.isConcluded()) {
			if (this.resgateService.getPropostaFromResgate() === true) {
				let value = 0;
				if (this.formControl.field.title1 !== null && typeof this.formControl.field.title1.titleSQuantity != 'undefined' && this.formControl.field.title1.titleSQuantity !== null) {
					value += this.formControl.field.title1.titleSQuantity * this.formControl.field.title1.value;
				}
				if (this.formControl.field.title2 !== null && typeof this.formControl.field.title2.titleSQuantity != 'undefined' && this.formControl.field.title2.titleSQuantity !== null) {
					value += this.formControl.field.title2.titleSQuantity * this.formControl.field.title2.value;
				}
				if (this.formControl.field.title3 !== null && typeof this.formControl.field.title3.titleSQuantity != 'undefined' && this.formControl.field.title3.titleSQuantity !== null) {
					value += this.formControl.field.title3.titleSQuantity * this.formControl.field.title3.value;
				}
				const propostaResgate = {
					Id: this.formControl.field.Id,
					Name: this.formControl.field.numberProposta,
					value
				};
				this.resgateService.setObjectProposta(propostaResgate);
				this.$state.go('resgate.type');
			} else {
				this.$state.go('proposta-detail', { id: this.formControl.field.Id });
			}
		}
	}

	// hasConclusion() {

	// 	return this.formControl.field.formStep === 'proposta.file';
	// }

	// finishProposta() {
	// console.log('finishProposta');

	// this.$scope.$broadcast('finishProposta');
	// }
}

module.exports = propostaController;
