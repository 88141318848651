"use strict";

import VisualForceApi from "../../core/sfdc/visual-force-api.js";

class propostaPaymentService extends VisualForceApi {
	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	getBankList() {
		return ["341	BANCO ITAÚ", "033	BANCO SANTANDER (BRASIL) S.A."];
	}

	getBestDayList() {
		const myDaysBest = [];
		for (let i = 1; i <= 25; i++) {
			myDaysBest.push(i.toString());
		}
		return myDaysBest;
	}

	getBestDayService(melhorDia) {
		const deferred = this.$q.defer();

		console.log(melhorDia);

		const cap_access_token = localStorage.getItem("cap_access_token");

		const request = {
			dataInformada: melhorDia,
			accessToken: cap_access_token
		};

		console.log(request);

		super
			.invoke("CapCommunityPropostaControllerExt.melhorDataPagamento", request)
			.then((response) => {
				deferred.resolve(response);
			});

		return deferred.promise;
	}

	getParcelas(valorTotalProposta) {
		const deferred = this.$q.defer();

		console.log("valorTotalProposta: ", valorTotalProposta);

		const cap_access_token = localStorage.getItem("cap_access_token");

		const request = {
			valorTotalProposta,
			accessToken: cap_access_token
		};

		console.log(request);

		super
			.invoke("CapCommunityPropostaControllerExt.getParcelas", request)
			.then((response) => {
				console.log("resultado busca parcela (service): ", response);
				deferred.resolve(response);
			});

		return deferred.promise;
	}

	searchAccount(searchKeyWord) {
		const deferred = this.$q.defer();
		const propostaType = sessionStorage.getItem("tipo_lista");

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "Account"
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		super
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchAccountFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				data.obtainedRecords.push(this.addBtnCreateAccount());
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}

	addBtnCreateAccount() {
		return {
			action: "modalHandle",
			Name: "+ Criar Cadastro"
		};
	}

	searchProfession(searchKeyWord, isPersonAccount) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: isPersonAccount ? "OCUPACAO__c" : "PROFISSAO__c", //PROFISSAO__c
				isPersonAccount
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		super
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);

				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}

	searchNacionalidade(searchKeyWord) {
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem('cap_access_token');

		super.invoke('CapCommunityPaisCtrlExt.consultarNacionalidade', searchKeyWord, cap_access_token).then((data) => {
			deferred.resolve(data.nacionalidades);
		});

		return deferred.promise;
	}

	getGrauParentesco(searchString) {
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem('cap_access_token');

		super.invoke('CapCommunityPropostaControllerExt.getGrauParentesco', cap_access_token, searchString).then((data) => {
			console.log('Retorno do backend: ', data);
			deferred.resolve(data.parentescoSearch);
		});
		console.log('Retorno da service: ', deferred.promise);

		return deferred.promise;
	}

	searchResidenciaFiscal(searchKeyWord) {
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem("cap_access_token");

		super.invoke(
				'CapCommunityAccountControllerExt.getResidenciaFiscal', searchKeyWord, cap_access_token)
			.then((data) => {
				deferred.resolve(data.paises);
				console.log('Retorno paises: ', data);
			});
		return deferred.promise;

	}

	getParcelasDG(valorTitulo, valorSeguro) {
		const deferred = this.$q.defer();

		console.log("valorTitulo: ", valorTitulo);

		const cap_access_token = localStorage.getItem("cap_access_token");

		const request = {
			valorTitulo,
			valorSeguro,
			accessToken: cap_access_token
		};

		console.log(request);

		super
			.invoke("CapCommunityPropostaControllerExt.getParcelasDG", request)
			.then((response) => {
				console.log("resultado busca parcela (service): ", response);
				deferred.resolve(response);
			});

		return deferred.promise;
	}

	getURLPagamentoCredito(formField) {
		const deferred = this.$q.defer();

		console.log("parcela selecionada: ", formField.parcelaSelecionada);
		console.log("parcela valor: ", formField.parcelaValor);

		const request = {
			valor: formField.parcelaValor,
			valorLiquido: formField.cartaoValorLiquido,
			numeroParcelas: formField.parcelaSelecionada,
			clientStreetAddress: formField.clientStreetAddress,
			clientStreetNumber: formField.clientStreetNumber,
			clientAddressComplement: formField.clientAddressComplement,
			clientCep: formField.clientCep,
			clientCity: formField.clientCity,
			clientDistrict: formField.clientDistrict,
			clientUf: formField.clientUf,
			nome: formField.account.Name,
			cpf: formField.account.cpf,
			email: formField.account.email
		};

		console.log(request);

		const cap_access_token = localStorage.getItem("cap_access_token");

		super
			.invoke(
				"CapCommunityPropostaControllerExt.solGerarURLPagamentoCredito",
				request,
				cap_access_token
			)
			.then((response) => {
				console.log("resultado url pagamento cartao de credito: ", response);
				deferred.resolve(response);
			});

		return deferred.promise;
	}
}

module.exports = propostaPaymentService;
