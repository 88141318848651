'use strict';

import VisualForceApi from '../../core/sfdc/visual-force-api.js';

class NewAccountService extends VisualForceApi {
	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	createNewAccount(newAccount) {
		const cap_access_token = localStorage.getItem('cap_access_token');
		newAccount.accessToken = cap_access_token;

		return super.invoke('CapCommunityAccountControllerExt.createNewAccount', newAccount);
	}

	getAccountDetails(accountId) {
		const cap_access_token = localStorage.getItem('cap_access_token');

		return super.invoke('CapCommunityAccountControllerExt.getAccountDetails', accountId, cap_access_token);
	}

	searchResidenciaFiscal(searchKeyWord) {
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem('cap_access_token');

		super.invoke('CapCommunityAccountControllerExt.getResidenciaFiscal', searchKeyWord, cap_access_token).then((data) => {
			deferred.resolve(data.paises);
		});

		return deferred.promise;
	}

	prevencaoValidaPessoa(cpfCnpj, nome, dataNascimento) {
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem('cap_access_token');

		const data = {
			cpfCnpj,
			nome,
			dataNascimento
		};

		console.log('data enviada prevencaoValidaPessoa: ', data);

		super.invoke('CapCommunityCrivoCtrlExt.prevencaoValidaPessoaFisica', data).then((data) => {
			deferred.resolve(data);
		});

		return deferred.promise;
	}

	searchPais(searchKeyWord) {
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem('cap_access_token');

		super.invoke('CapCommunityPaisCtrlExt.consultarPais', searchKeyWord, cap_access_token).then((data) => {
			deferred.resolve(data.paises);
		});

		return deferred.promise;
	}

	searchNacionalidade(searchKeyWord) {
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem('cap_access_token');

		super.invoke('CapCommunityPaisCtrlExt.consultarNacionalidade', searchKeyWord, cap_access_token).then((data) => {
			deferred.resolve(data.nacionalidades);
		});

		return deferred.promise;
	}
}

module.exports = NewAccountService;
